import { h, render } from 'preact';
import MostRecent from '../../components/MostRecent/MostRecent';
import PopularPodcasts from '../../components/PopularPodcasts';
import inView from '~/utils/in-view';
import createLeeAdObserver from '~/utils/leeadObserver';
import axios from 'axios';
import 'intersection-observer';

function observeGrid() {
  inView('.items-grid').once('enter', (el) => {
    if (!el.classList.contains('items-grid--in-view')) {
      el.className += ' items-grid--in-view';
    }
  });
}

function createNodes(htmlString) {
  const outer = document.createElement('div');
  outer.innerHTML = htmlString;
  return outer.children;
}

// define lastCharId on window for typescript
declare global {
  interface Window {
    lastChartId: number | false;
  }
}

function topchart() {
  observeGrid();
  const adObserver = createLeeAdObserver();

  const lastChartBlock = document.querySelector(
    'section.container .row > section.items-grid:last-of-type',
  );
  const root = lastChartBlock?.parentElement;
  let nextChartBlockUrl = window.chartBlockUrl;
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (nextChartBlockUrl != null) {
            axios.get(nextChartBlockUrl).then(({ data, headers }) => {
              const sections = createNodes(data);
              root?.append(...sections);
              const ads = document.querySelectorAll('[data-cnt][data-pbid]');
              adObserver.add(ads);
              const spinners = root
                ? [...root.querySelectorAll('.spinner')]
                : [];

              nextChartBlockUrl = headers.link.length > 0 ? headers.link : null;
              // if we have more blocks to fetch dont remove the last spinner
              if (nextChartBlockUrl != null) {
                spinners.pop();
              }
              spinners.forEach((n) => n.parentNode?.removeChild(n));
              observeGrid();
              observer.observe(
                root.querySelector(
                  'section.container .row > section.items-grid:last-of-type',
                ),
              );
            });
          }
        }
      });
    },
    {
      root: null,
      rootMargin: '400px',
    },
  );

  observer.observe(lastChartBlock);

  const popularPodcastsNode = document.querySelector('#popularpodcasts');
  if (popularPodcastsNode) {
    render(
      h(PopularPodcasts, {
        title: 'Populärt på poddtoppen just nu',
        action: '/populara',
      }),
      popularPodcastsNode,
    );
  }

  const mostRecentNode = document.querySelector('#mostrecent');
  if (mostRecentNode) {
    render(
      h(MostRecent, {
        title: 'Senast besökta',
      }),
      mostRecentNode,
    );
  }
}

topchart();
